
// test sub-page button selection (to test page), adding event listener for utm parameter
if ($('body.page_10').length > 0) {

    let testFormBtn = document.getElementById("bob-frame-btn-p10-1");
    testFormBtn.addEventListener("click", addUtmParaFormFunc, false);
    testFormBtn.myParam = testFormBtn;

    let testHeroBtn = document.getElementById("btn-p10-hero");
    testHeroBtn.addEventListener("click", addUtmParaFunc, false);
    testHeroBtn.myParam = testHeroBtn;
}

// borderline sub-page button selection (to test page), adding event listener for utm parameter
if ($('body.page_8').length > 0) {

    let borderlineFormBtn = document.getElementById("btn-p8-frame-1");
    borderlineFormBtn.addEventListener("click", addUtmParaFormFunc, false);
    borderlineFormBtn.myParam = borderlineFormBtn;

    let borderlineTestBtn = document.getElementById("btn2-p8-zum-fragebogen");
    borderlineTestBtn.addEventListener("click", addUtmParaFunc, false);
    borderlineTestBtn.myParam = borderlineTestBtn;
}

// facts sub-page button selection (to test page), adding event listener for utm parameter
if ($('body.page_15').length > 0) {

    let factsFormBtn = document.getElementById("p15-btn1-frame");
    factsFormBtn.addEventListener("click", addUtmParaFormFunc, false);
    factsFormBtn.myParam = factsFormBtn;

    let factsTestBtn = document.getElementById("btn2-p15-zum-selbsttest");
    //console.log(factsTestBtn);
    factsTestBtn.addEventListener("click", addUtmParaFunc, false);
    factsTestBtn.myParam = factsTestBtn;
}

// selbstverletzungen sub-page button selection (to test page), adding event listener for utm parameter
if ($('body.page_11').length > 0) {
    let selbstverletTestHeroBtn = document.getElementById("btn1-p11-direkt-zum-selbsttest");
    selbstverletTestHeroBtn.addEventListener("click", addUtmParaFunc, false);
    selbstverletTestHeroBtn.myParam = selbstverletTestHeroBtn;

    let selbstverletTestBtn = document.getElementById("btn2-p11-zum-selbsttest");
    selbstverletTestBtn.addEventListener("click", addUtmParaFunc, false);
    selbstverletTestBtn.myParam = selbstverletTestBtn;
}

// syndrom sub-page button selection (to test page), adding event listener for utm parameter
if ($('body.page_9').length > 0) {

    let syndromFormBtn = document.getElementById("bob-frame-btn-p9-1");
    syndromFormBtn.addEventListener("click", addUtmParaFormFunc, false);
    syndromFormBtn.myParam = syndromFormBtn;

    let syndromTestBtn = document.getElementById("btn2-p9-direkt-zum-fragebogen");
    syndromTestBtn.addEventListener("click", addUtmParaFunc, false);
    syndromTestBtn.myParam = syndromTestBtn;
}

// was-hilft-bei-borderline sub-page button selection (to test page), adding event listener for utm parameter
if ($('body.page_14').length > 0) {
    let hilftTestHeroBtn = document.getElementById("btn1-p14-zum-borderline-test");
    hilftTestHeroBtn.addEventListener("click", addUtmParaFunc, false);
    hilftTestHeroBtn.myParam = hilftTestHeroBtn;

    let hilftTestBtn = document.getElementById("btn2-p14-zum-borderline-test");
    hilftTestBtn.addEventListener("click", addUtmParaFunc, false);
    hilftTestBtn.myParam = hilftTestBtn;
}

// was-ist-borderline sub-page button selection (to test page), adding event listener for utm parameter
if ($('body.page_13').length > 0) {
    let p13TestHeroBtn = document.getElementById("btn1-p13-direkt-zum-selbsttest");
    p13TestHeroBtn.addEventListener("click", addUtmParaFunc, false);
    p13TestHeroBtn.myParam = p13TestHeroBtn;

    let p13TestBtn = document.getElementById("btn2-p13-zum-borderline-test");
    p13TestBtn.addEventListener("click", addUtmParaFunc, false);
    p13TestBtn.myParam = p13TestBtn;
}


function addUtmParaFunc(event) {

    let btn = event.currentTarget.myParam;

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let utmSource = urlParams.get('utm_source');
    let utmMedium = urlParams.get('utm_medium');
    let utmCampaign = urlParams.get('utm_campaign');

    if ( urlParams.has('utm_source') || urlParams.has('utm_medium') || urlParams.has('utm_campaign') ) {
        btn.href = btn.href + "?";
    }

    if ( urlParams.has('utm_source') ) {
        btn.href = btn.href + "utm_source=" + utmSource + "&";
    }

    if ( urlParams.has('utm_medium') ) {
        btn.href = btn.href + "utm_medium=" + utmMedium + "&";

    }

    if ( urlParams.has('utm_campaign')  ) {
        btn.href = btn.href + "utm_campaign=" + utmCampaign;
    }

    if ( btn.href.charAt(btn.href.length - 1) === "&") {
        btn.href = btn.href.slice(0, -1);
    }
}

function addUtmParaFormFunc(event) {

    let btn = event.currentTarget.myParam;

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let utmSource = urlParams.get('utm_source');
    let utmMedium = urlParams.get('utm_medium');
    let utmCampaign = urlParams.get('utm_campaign');

    if ( urlParams.has('utm_source') ) {
        sessionStorage.setItem("utm_source", utmSource);
    }

    if ( urlParams.has('utm_medium') ) {
        sessionStorage.setItem("utm_medium", utmMedium);
    }

    if ( urlParams.has('utm_campaign')  ) {
        sessionStorage.setItem("utm_campaign", utmCampaign);
    }
}