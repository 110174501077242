import URLWatcher from "../../../libs/v2/diga/scripts/URLWatcher";

import getInsuranceList from './fetchInsuranceList.js';

(function ($) {

    $(document).ready(function () {

        // ********** jQuery plugins ************ 

        // PAGE1 PATIENTS AND REZEPT FORMS
        if ($('body.page_1').length > 0) {
            $('body').digaAccess();
            $('body').formRezept(document.bob.rezept);
        }

        // PAGE3 PATIENTS FORMS
        if ($('body.page_3').length > 0) {
            $('body').digaRenewal();
        }

        // PAGE5 REZEPT FORM
        if ($('body.page_5').length > 0) {
            $('body').formRezept(document.bob.rezept);
        }

        $('body').scrollToAnchors();

        $('.glyphicons').glyphClone();

        $('.jumbotron a[title^=button], .passage a[title^=button], .usp-content a[title^=button], .footer a[title^=button]').mdLinkToButton();

        $('.btn').createTrackingClass();

        $('body').etrackerButtonEvents();

        $('.body-content').linkTap();

        $(window).scrollTopArrow();

        $('.navbar').menuScrollAnimations();

        $('.navbar').menuActive();

        $('body').fadePage();

        $('.bob-frame-show').bobframe();

        new URLWatcher();
    });

}(jQuery));